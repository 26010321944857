import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { Metadata } from '../components/core/metadata';
import { create } from '../helpers/bem';

import './experiments.scss';

const bem = create('page-experiments');

const ExperimentsPage = () => {
  const { t } = useTranslation();

  return (
    <Metadata
      title={t('Experiments')}
      description="Code experiments written by Frontend Developer Yves Rijckaert"
    >
      <GroupAnimation as="section" className={bem()}>
        <GroupedBlockAnimation delay={500} as="h2" className={bem('headline')}>
          <span>Coming soon</span>
        </GroupedBlockAnimation>
      </GroupAnimation>
    </Metadata>
  );
};

export default ExperimentsPage;
